import React from 'react';
import { Link } from 'gatsby';

const BossResults = () => {
  return (
    <div className='mainContainer'>
        <div className="relative inmode-dark-container px-6 py-10 lg:py-20">
        
            <h3 className='header-h3 mb-3'>Results Speak Louder</h3>
            <div className="mt-10 lg:mt-20 three-card-grid-layout">
                <div className="orange-card">
                    <svg xmlns="http://www.w3.org/2000/svg" width="230" height="215" viewBox="0 0 230 215" fill="none">
                        <path d="M115.052 208.004C105.302 208.004 95.4273 207.505 85.7188 206.526L49.4808 202.873C30.6246 200.968 15.7763 186.481 13.3674 167.642L10.9481 148.71L9.97204 141.081C7.14083 119.12 7.14083 97.0175 9.97204 74.7723L10.8546 67.7885L13.4089 47.5451C15.7971 28.6755 30.6454 14.1669 49.5224 12.2622L85.7085 8.60903C95.4169 7.63068 105.281 7.1311 115.042 7.1311C124.802 7.1311 134.666 7.63068 144.375 8.60903L180.55 12.2622C199.438 14.1669 214.286 28.6859 216.674 47.5555L219.239 67.9134L220.132 74.9908C222.942 97.0973 222.942 119.055 220.132 140.863L219.145 148.585L216.716 167.632C214.317 186.481 199.458 200.968 180.602 202.873L144.375 206.526C134.666 207.505 124.802 208.004 115.042 208.004H115.052Z" fill="#F47F23"/>
                        <path d="M115.052 13.3655C124.605 13.3655 134.261 13.8547 143.762 14.8122L179.938 18.4654C195.918 20.0786 208.482 32.36 210.496 48.3362L213.061 68.6941C213.383 71.2128 213.673 73.565 213.954 75.7819C216.695 97.3541 216.695 118.784 213.954 140.072C213.642 142.466 213.31 145.036 212.957 147.805L210.527 166.851C208.492 182.796 195.928 195.057 179.969 196.67L143.741 200.323C134.24 201.281 124.584 201.77 115.031 201.77C105.478 201.77 95.8219 201.281 86.3211 200.323L50.0831 196.67C34.1342 195.057 21.5599 182.807 19.5248 166.862L17.1055 147.93C16.7524 145.203 16.4305 142.663 16.119 140.29C13.3571 118.864 13.3571 97.2916 16.119 75.5737C16.3994 73.3881 16.6901 71.0671 17.0016 68.59L19.5559 48.3466C21.5703 32.3808 34.1446 20.0994 50.1142 18.4862L86.3003 14.833C95.8011 13.8755 105.458 13.3863 115.01 13.3863M115.052 0.875977C105.053 0.875977 95.0535 1.37556 85.0959 2.38513L48.9098 6.03831C27.25 8.22398 9.97207 25.1056 7.23086 46.7542L4.67655 66.9976C-0.878563 111.002 -0.847413 105.497 4.77 149.501L7.18933 168.433C9.95131 190.051 27.2189 206.901 48.8578 209.087L85.0959 212.74C95.0535 213.749 105.053 214.249 115.052 214.249C125.051 214.249 135.05 213.749 145.008 212.74L181.236 209.087C202.875 206.901 220.142 190.051 222.904 168.423L225.334 149.376C230.931 105.455 230.962 111.044 225.427 67.1225L222.863 46.7646C220.132 25.1161 202.843 8.22398 181.184 6.03831L145.008 2.38513C135.05 1.37556 125.051 0.875977 115.052 0.875977Z" fill="#964321"/>
                    </svg>
                    <div className="absolute w-full h-full z-2 top-[20%] left-0 flex flex-col items-center">
                        <p className='orange-top-text'>INCREASED YOY<br/>REVENUE</p>
                        <h4 className='orange-bot-text'>32%</h4>
                    </div>
                </div>

                <div className="orange-card">
                    <svg xmlns="http://www.w3.org/2000/svg" width="230" height="215" viewBox="0 0 230 215" fill="none">
                        <path d="M115.052 208.004C105.302 208.004 95.4273 207.505 85.7188 206.526L49.4808 202.873C30.6246 200.968 15.7763 186.481 13.3674 167.642L10.9481 148.71L9.97204 141.081C7.14083 119.12 7.14083 97.0175 9.97204 74.7723L10.8546 67.7885L13.4089 47.5451C15.7971 28.6755 30.6454 14.1669 49.5224 12.2622L85.7085 8.60903C95.4169 7.63068 105.281 7.1311 115.042 7.1311C124.802 7.1311 134.666 7.63068 144.375 8.60903L180.55 12.2622C199.438 14.1669 214.286 28.6859 216.674 47.5555L219.239 67.9134L220.132 74.9908C222.942 97.0973 222.942 119.055 220.132 140.863L219.145 148.585L216.716 167.632C214.317 186.481 199.458 200.968 180.602 202.873L144.375 206.526C134.666 207.505 124.802 208.004 115.042 208.004H115.052Z" fill="#F47F23"/>
                        <path d="M115.052 13.3655C124.605 13.3655 134.261 13.8547 143.762 14.8122L179.938 18.4654C195.918 20.0786 208.482 32.36 210.496 48.3362L213.061 68.6941C213.383 71.2128 213.673 73.565 213.954 75.7819C216.695 97.3541 216.695 118.784 213.954 140.072C213.642 142.466 213.31 145.036 212.957 147.805L210.527 166.851C208.492 182.796 195.928 195.057 179.969 196.67L143.741 200.323C134.24 201.281 124.584 201.77 115.031 201.77C105.478 201.77 95.8219 201.281 86.3211 200.323L50.0831 196.67C34.1342 195.057 21.5599 182.807 19.5248 166.862L17.1055 147.93C16.7524 145.203 16.4305 142.663 16.119 140.29C13.3571 118.864 13.3571 97.2916 16.119 75.5737C16.3994 73.3881 16.6901 71.0671 17.0016 68.59L19.5559 48.3466C21.5703 32.3808 34.1446 20.0994 50.1142 18.4862L86.3003 14.833C95.8011 13.8755 105.458 13.3863 115.01 13.3863M115.052 0.875977C105.053 0.875977 95.0535 1.37556 85.0959 2.38513L48.9098 6.03831C27.25 8.22398 9.97207 25.1056 7.23086 46.7542L4.67655 66.9976C-0.878563 111.002 -0.847413 105.497 4.77 149.501L7.18933 168.433C9.95131 190.051 27.2189 206.901 48.8578 209.087L85.0959 212.74C95.0535 213.749 105.053 214.249 115.052 214.249C125.051 214.249 135.05 213.749 145.008 212.74L181.236 209.087C202.875 206.901 220.142 190.051 222.904 168.423L225.334 149.376C230.931 105.455 230.962 111.044 225.427 67.1225L222.863 46.7646C220.132 25.1161 202.843 8.22398 181.184 6.03831L145.008 2.38513C135.05 1.37556 125.051 0.875977 115.052 0.875977Z" fill="#964321"/>
                    </svg>
                    <div className="absolute w-full h-full z-2 top-[20%] left-0 flex flex-col items-center">
                        <p className='orange-top-text'>INCREASED YOY CONVERSION RATE</p>
                        <h4 className='orange-bot-text'>43%</h4>
                    </div>
                </div>

                <div className="orange-card">
                    <svg xmlns="http://www.w3.org/2000/svg" width="230" height="215" viewBox="0 0 230 215" fill="none">
                        <path d="M115.052 208.004C105.302 208.004 95.4273 207.505 85.7188 206.526L49.4808 202.873C30.6246 200.968 15.7763 186.481 13.3674 167.642L10.9481 148.71L9.97204 141.081C7.14083 119.12 7.14083 97.0175 9.97204 74.7723L10.8546 67.7885L13.4089 47.5451C15.7971 28.6755 30.6454 14.1669 49.5224 12.2622L85.7085 8.60903C95.4169 7.63068 105.281 7.1311 115.042 7.1311C124.802 7.1311 134.666 7.63068 144.375 8.60903L180.55 12.2622C199.438 14.1669 214.286 28.6859 216.674 47.5555L219.239 67.9134L220.132 74.9908C222.942 97.0973 222.942 119.055 220.132 140.863L219.145 148.585L216.716 167.632C214.317 186.481 199.458 200.968 180.602 202.873L144.375 206.526C134.666 207.505 124.802 208.004 115.042 208.004H115.052Z" fill="#F47F23"/>
                        <path d="M115.052 13.3655C124.605 13.3655 134.261 13.8547 143.762 14.8122L179.938 18.4654C195.918 20.0786 208.482 32.36 210.496 48.3362L213.061 68.6941C213.383 71.2128 213.673 73.565 213.954 75.7819C216.695 97.3541 216.695 118.784 213.954 140.072C213.642 142.466 213.31 145.036 212.957 147.805L210.527 166.851C208.492 182.796 195.928 195.057 179.969 196.67L143.741 200.323C134.24 201.281 124.584 201.77 115.031 201.77C105.478 201.77 95.8219 201.281 86.3211 200.323L50.0831 196.67C34.1342 195.057 21.5599 182.807 19.5248 166.862L17.1055 147.93C16.7524 145.203 16.4305 142.663 16.119 140.29C13.3571 118.864 13.3571 97.2916 16.119 75.5737C16.3994 73.3881 16.6901 71.0671 17.0016 68.59L19.5559 48.3466C21.5703 32.3808 34.1446 20.0994 50.1142 18.4862L86.3003 14.833C95.8011 13.8755 105.458 13.3863 115.01 13.3863M115.052 0.875977C105.053 0.875977 95.0535 1.37556 85.0959 2.38513L48.9098 6.03831C27.25 8.22398 9.97207 25.1056 7.23086 46.7542L4.67655 66.9976C-0.878563 111.002 -0.847413 105.497 4.77 149.501L7.18933 168.433C9.95131 190.051 27.2189 206.901 48.8578 209.087L85.0959 212.74C95.0535 213.749 105.053 214.249 115.052 214.249C125.051 214.249 135.05 213.749 145.008 212.74L181.236 209.087C202.875 206.901 220.142 190.051 222.904 168.423L225.334 149.376C230.931 105.455 230.962 111.044 225.427 67.1225L222.863 46.7646C220.132 25.1161 202.843 8.22398 181.184 6.03831L145.008 2.38513C135.05 1.37556 125.051 0.875977 115.052 0.875977Z" fill="#964321"/>
                    </svg>
                    <div className="absolute w-full h-full z-2 top-[20%] left-0 flex flex-col items-center">
                        <p className='orange-top-text'>IMPROVED<br/>PAGESPEED SCORE</p>
                        <h4 className='orange-bot-text'>C→A</h4>
                    </div>
                </div>
            </div>

            <div className="w-full flex items-center justify-center mt-11">
                <Link to="/fuel#boss_form" className='case-study-button lg:mb-0 mb-8'><span>Access Case Study</span></Link>
            </div>
            
        </div>
    </div>
  )
}

export default BossResults