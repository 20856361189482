import React from 'react';
import { Link } from 'gatsby';

//images
import laptop from "../../assets/images/boss/boss_laptop.png";

const BossHero = () => {
  return (
      <div className="mainContainer">
        <div className="relative boss-hero-img-container px-6">
          <div className="lg:pt-0 pt-16 flex flex-col items-center">
            <h1 className='header-h1 mb-2 lg:m-0'>Explore</h1>
            <h2 className='header-h2 mb-4'>The Inmode estore case study</h2>
            <p className='max-w-[1103px] body-text mb-[30px]'>See how Full Funnel Growth increased revenue for InMode by creating and launching a best-in-class eComm experience through a custom Shopify Plus storefront.</p>
            <Link to="/fuel#boss_form" className='case-study-button'><span>Access Case Study</span></Link>
          </div>
          <div className='flex items-center justify-center lg:mt-20 mt-7'><img alt='boss hero page' src={laptop}/></div>
        </div>
      </div>
  )
}

export default BossHero