import React, { useState } from "react";
import { Button } from "react-bootstrap";

const BossForm = () => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [website, setWebsite] = useState("https://");

    const handleSubmit = (event) => {
        event.preventDefault();
      
        const myForm = event.target;
        const formData = new FormData(myForm);
        
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams(formData).toString(),
        })
          .then(() => {
            setFormSubmitted(true);
            setTimeout(() => {
                if(typeof window !== "undefined"){
                    window.location.href = "https://ffg.digital/case-studies/how-we-increased-marketing-qualified-leads-by-533percent-for-inmode";
                }
                setFormSubmitted(false);
            }, 2000);
        })
          .catch((error) => alert(error));
      };

  return (
    <div id="boss_form" className='mainContainer bg-[#000000]'>
        <div className="relative px-6 pb-8 lg:py-20 flex flex-col items-center">
            <h3 className='header-h3 mb-4'>Fueling Growth with FFG</h3>
            <p className='body-text max-w-[945px] mb-8 lg:mb-12'>We're not just an agency; we're your dedicated growth partner, ready to adapt, collaborate, and deliver results. Partner with us today for custom tailored strategies and impactful solutions.</p>
            {formSubmitted && (<div>
                <p className="font-montserrat text-white font-medium leading-[108%] !text-[18px] md:!text-[24px] md:text-center">
                    We will be contacting you ASAP on the next steps!    
                </p>
            </div>
            )}
            {!formSubmitted && <form
                action="/case-studies/how-we-increased-marketing-qualified-leads-by-533percent-for-inmode"
                className="flex flex-col items-center w-full max-w-[610px]"
                data-netlify="true"
                method="POST"
                name="contact" 
                netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
            >
                <input name="form-name" type="hidden" value="contact"/>
                <div hidden>
                  <label>
                    Don&apos;t fill this part out: <input name="bot-field"/>
                  </label>
                </div>
                
                <label className="mb-2 font-montserrat w-full text-white">Full Name</label>
                <input
                    className="w-full mb-4 rounded p-2"
                    name="full-name"
                    required
                    type="name"
                />
                
                <label bsPrefix="label_text" className="mb-2 font-montserrat w-full text-white">Company</label>
                <input
                    className="w-full mb-4 rounded p-2"
                    name="company-name" 
                    required
                    type="companyName"
                />
                

                
                <label bsPrefix="label_text" className="mb-2 font-montserrat w-full text-white">Website URL</label>
                <input
                    className="w-full mb-4 rounded p-2"
                    name="website-url" 
                    onChange={(e) => setWebsite(e.target.value)}
                    required
                    type="url"
                    value={website}
                />
               
                <label bsPrefix="label_text" className="mb-2 font-montserrat w-full text-white">Email Address</label>
                <input
                    className="w-full mb-4 rounded p-2"
                    name="email"
                    required
                    type="email"
                />

                <div className="button__container">
                    <Button bsPrefix='hire_button hire_button--form' type='submit'>Submit</Button>
                </div>
            </form>}
        </div>
    </div>
  )
}

export default BossForm