import React from 'react';

//images
import img_banner from "../../assets/images/boss/inmode-banner.png";

const BossImageBanner = () => {
  return (
    <div className='mainContainer'>
        <img className="w-full h-full" alt="inmode image banner" src={img_banner}/>
    </div>
  )
}

export default BossImageBanner