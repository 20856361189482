import React from 'react';
import { Link } from 'gatsby';

//images
import inmode_1 from "../../assets/images/boss/inmode-1.png";


const BossImageText = () => {
  return (
    <div className='mainContainer'>
        <div className="relative bg-container px-6 py-[20px] lg:py-[75px]">
            <div className="flex flex-col items-center">
                <h3 className='header-h3 mb-3'>Our Approach</h3>
                <p className='body-text max-w-[1064px]'>InMode is a leading global provider of medical aesthetic devices, specializing in radiofrequency, light, and laser-based technologies. They serve clinics worldwide, offering technologies, products, and a permissioned wholesale store.</p>
            </div>
            <div className="flex flex-col lg:flex-row items-center justify-evenly">
                <img alt="inmode-1" className="w-full lg:w-1/2" src={inmode_1}/>
                <div className="info-container w-full lg:w-1/2 flex flex-col items-center lg:items-start">
                    <p>Clinics using InMode technologies and workstations can purchase consumables and marketing materials for their machines via InMode’s permissioned wholesale store. They can also download resources such as photos and videos relating to the technologies and workstations they own via a section of the wholesale store called the Resource Center.</p>
                    <p className='mb-12'>At Full Funnel Growth, we facilitated the transition from their existing setup on Wordpress to Shopify for both a more scalable admin experience and also a more enjoyable experience for clinics.</p>
                    <Link to="/fuel#boss_form" className='case-study-button lg:mb-0 mb-8'><span>Access Case Study</span></Link>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BossImageText