import React from 'react';

//images
import app_img from "../../assets/images/boss/app.png";
import data_img from "../../assets/images/boss/data.png";
import integration_img from "../../assets/images/boss/integration.png";
import purchase_img from "../../assets/images/boss/purchase.png";
import resource_img from "../../assets/images/boss/resource.png";
import search_img from "../../assets/images/boss/search.png";
import web_store_img from "../../assets/images/boss/web-store.png";

const BossSolutionCards = () => {
  return (
    <div className='mainContainer bg-[#032F46]'>
        <div className="relative px-6 py-10 lg:py-20">
            <h3 className='header-h3 mb-3'>Tailored Solutions for InMode</h3>
            <div className="mt-10 lg:mt-20 card-grid-layout">

                <div className='boss-card'>
                    <img className="ml-4 mb-3" src={web_store_img}/>
                    <h4 className="ml-4 mb-3">Web Store</h4>
                    <ul>
                        <li>Implemented a permissioned web store for approved customers.</li>
                        <li>Restricted access to approved users only.</li>
                        <li>Enabled new users to request access via a form.</li>
                        <li>Applied limits on product access, purchase limits, and order approvals.</li>
                    </ul>
                </div>

                <div className='boss-card'>
                    <img className="ml-4 mb-3" src={purchase_img}/>
                    <h4 className="ml-4 mb-3">Purchase Limits and Order Approvals</h4>
                    <ul>
                        <li>Managed single order purchase limits through a utility script in the theme code.</li>
                        <li>Implemented monthly and quarterly purchase limits using Shopify’s draft order API.</li>
                        <li>Developed an order approval process involving manual review and staff member authentication.</li>
                    </ul>
                    
                </div>

                <div className='boss-card'>
                    <img className="ml-4 mb-3" src={search_img}/>
                    <h4 className="ml-4 mb-3">Search</h4>
                    <ul>
                        <li>Utilized Algolia for customized search functionality.</li>
                        <li>Applied filters based on customer permissions to restrict search results.</li>
                    </ul>
                </div>

                <div className='boss-card'>
                    <img className="ml-4 mb-3" src={resource_img}/>
                    <h4 className="ml-4 mb-3">Resource Center</h4>
                    <ul>
                        <li>Established a Resource Center using Contentful for hosting and managing resources.</li>
                        <li>Utilized GatsbyJS to build static pages for efficient resource handling.</li>
                        <li>Integrated a download proxy to address browser issues with Contentful's asset hosting.</li>
                    </ul>
                </div>

                <div className='boss-card'>
                    <img className="ml-4 mb-3" src={app_img}/>
                    <h4 className="ml-4 mb-3">Custom App</h4>
                    <ul>
                        <li>Built a custom Shopify app for managing various admin tasks.</li>
                        <li>Utilized Firebase for database storage.</li>
                        <li>Implemented a simple PIN code system for staff member authentication.</li>
                        <li>Enabled logging of all actions taken by staff members.</li>
                    </ul>
                </div>

                <div className='boss-card'>
                    <img className="ml-4 mb-3" src={integration_img}/>
                    <h4 className="ml-4 mb-3">Seamless Integration</h4>
                    <ul>
                        <li>Maintained a seamless integration with the web store using iFrames.</li>
                        <li>Incorporated loading spinner overlays during iFrame initialization.</li>
                        <li>Ensured dynamic updates of the iFrame content based on user permissions.</li>
                    </ul>
                </div>
            </div>

            <div className="w-full flex items-center justify-center mt-11">
            <div className='boss-card lg:h-[426px]'>
                <img className="ml-4 mb-3" src={data_img}/>
                <h4 className="ml-4 mb-3">Data Syncing</h4>
                <ul>
                    <li>Implemented webhooks to sync data between Shopify, Contentful, and Algolia.</li>
                    <li>Managed automatic copying of permission sets from Contentful to a Shopify metafield
                    </li>
                </ul>
            </div>
            </div>

        </div>
    </div>
  )
}

export default BossSolutionCards