import React from 'react';
import "../layouts/styles/boss/style.css";

import Layout from '../layouts';
import BossHero from '../components/boss/boss-hero';
import BossImageText from '../components/boss/boss-image-text';
import BossImageBanner from '../components/boss/boss-image-banner';
import BossSolutionCards from '../components/boss/boss-solution-cards';
import BossResults from '../components/boss/boss-results';
import BossForm from '../components/boss/boss-form';

const Fuel = () => {
  return (
    <Layout>
        <BossHero/>
        <BossImageText/>
        <BossImageBanner/>
        <BossSolutionCards/>
        <BossResults/>
        <BossForm/>
    </Layout>
  )
}

export default Fuel